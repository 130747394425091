<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  #app{
    height: calc(100vh - 53px);
  }
</style>
